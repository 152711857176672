import * as Phaser from 'phaser';
import { Musician, MusicianClickedEvent, Musicians } from './musician';
import { BAGPIPES, BASS_DRUM, BOMBARDE, BRETON_BAGPIPES, Instrument, SNARE_DRUM } from './instrument';
import { EventDispatcher } from './eventDispatcher';
import POINTER_DOWN = Phaser.Input.Events.POINTER_DOWN;
import { GoodSpot } from './goodSpot';
import Ellipse = Phaser.Geom.Ellipse;

enum GameState {
    BEGINNING,
    PLAYING,
    ENDING,
}

const GAME_SPEED = 300;
export default class Jahiner extends Phaser.Scene {

    private instr: Instrument[] = [];
    private goodSpot: GoodSpot;
    private emitter: EventDispatcher;
    private music: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private percussions: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private voice: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private playing: any[] = [];
    private gameState: GameState = GameState.BEGINNING;

    private players: Musician[] = [];
    private confettiInterval: NodeJS.Timeout;

    private hiddenPoint: Phaser.Math.Vector2 = new Phaser.Math.Vector2();
    private currentInstrument: number;

    constructor() {
        super('jahiner');
    }

    create() {
        const width = this.game.scale.width;
        const height = this.game.scale.height;
        const center_x = Number(width) / 2;
        const center_y = Number(height) / 2;
        const background = this.add.image(center_x, center_y, 'background');
        background.setDisplaySize(width, height);

        this.add.image(Number(width)/100*15, Number(height)/7, 'logo');

        this.hiddenPoint.x = center_x;
        this.hiddenPoint.y = -300;
        this.currentInstrument = 0;

        Musicians.forEach((name, index) => {
            const ellipse = new Ellipse(center_x, center_y, width*0.8, height*0.5);
            const { x, y } = Ellipse.GetPoint(ellipse, (180/(Musicians.length + 1) * (index+1)) / 360);
            const musician = new Musician(this, name, x, y);
            this.players.push(musician);
            this.instr.push(musician.showInstrument);
        });

        const directions: [number, number][] = this.players.map((player) => [player.x, player.y]);
        this.goodSpot = new GoodSpot(this, center_x, center_y-200, directions);
        this.physics.add.existing(this.goodSpot);
        this.add.existing(this.goodSpot);

        this.music = this.sound.add(`music`, {volume: 0.3});
        this.percussions = this.sound.add(`percussions`, {volume: 0.3});
        this.voice = this.sound.add(`voice`, {volume: 0.3});

        this.music.play({mute: true, loop: true});
        this.percussions.play({mute: true, loop: true});
        this.voice.play({mute: true, loop: true});

        const confettiTop = this.add.particles(0, 0, 'flares', {
            frame: ['blue', 'green', 'red', 'white', 'yellow'],
            lifespan: 3000,
            speed: {min: 500, max: 600},
            scale: {start: 0.5, end: 0},
            angle: {min: 0, max: 180},
            gravityY: 150,
            emitting: false,
        });
        const confettiPlayer = this.add.particles(0, 0, 'flares', {
            frame: ['blue', 'green', 'red', 'white', 'yellow'],
            lifespan: 2000,
            speed: {min: 450, max: 550},
            scale: {start: 0.4, end: 0},
            angle: {min: 150, max: 390},
            gravityY: 250,
            emitting: false,
        });

        this.emitter = EventDispatcher.getInstance();

        // Beginning animations
        setTimeout(() => {
            this.instr.forEach((instrument) => {
                this.physics.moveToObject(instrument, this.hiddenPoint, GAME_SPEED);
            })
        }, 2000)

        // Manage user inputs
        this.emitter.on('MUSICIAN_CLICKED', (event: MusicianClickedEvent) => {
            if (this.gameState !== GameState.PLAYING) return;
            if (this.playing.length >= 5) return;

            const found: Instrument | undefined = this.instr.find((instrument: Instrument) => {
                return this.physics.overlap(this.goodSpot, instrument);
            });
            // Clicked on any musician with no instrument highlighted
            if (!found) {
                this.resetAllAnimation(event);
            }

            // Clicked on any musician with an instrument highlighted
            if (found) {
                if (found.instrument === event.who.instrument) {
                    // A. Clicked on the right musician for the instrument highlighted
                    event.who.spotlightOn();
                    event.who.sayYes();
                    switch (found.instrument) {
                        case BRETON_BAGPIPES:
                            if (this.music.mute) {
                                this.music.mute = false;
                            }
                            if (!this.playing.includes(event.name)) {
                                this.playing.push(event.name);
                            }
                            break
                        case BOMBARDE:
                            if (this.voice.mute) {
                                this.voice.mute = false;
                            }
                            if (!this.playing.includes(event.name)) {
                                this.playing.push(event.name);
                            }
                            break
                        case SNARE_DRUM:
                            if (this.percussions.mute) {
                                this.percussions.mute = false;
                            }
                            if (!this.playing.includes(event.name)) {
                                this.playing.push(event.name);
                            }
                            break
                        case BAGPIPES:
                            if (this.music.mute) {
                                this.music.mute = false;
                            }
                            if (!this.playing.includes(event.name)) {
                                this.playing.push(event.name);
                            }
                            break
                        case BASS_DRUM:
                            if (this.percussions.mute) {
                                this.percussions.mute = false;
                            }
                            if (!this.playing.includes(event.name)) {
                                this.playing.push(event.name);
                            }
                            break
                    }

                    this.physics.moveToObject(found, event.who, GAME_SPEED * 1.2);
                    this.currentInstrument = loopIncrement(this.currentInstrument);

                    if (this.playing.length >= 5) {
                        this.explodeConfetti(confettiTop, width, confettiPlayer);
                        this.confettiInterval = setInterval(() => this.explodeConfetti(confettiTop, width, confettiPlayer), 1000);
                        setTimeout(() => {
                            const x = Number(width) / 2;
                            const y = Number(height) / 2;
                            const logo = new Phaser.GameObjects.Image(this, x, y, 'logo');
                            // const logo = this.add.image(x, y, 'logo');
                            const text = new Phaser.GameObjects.Text(this, x - logo.width / 2, y + logo.height / 2, 'Encore une fois', {
                                font: '3em'
                            });
                            const bgRect = this.add.rectangle(x, y + text.height / 2,
                                logo.width + 40,
                                logo.height + text.height + 40,
                                0x0D6625,
                                0.7
                            );
                            this.add.existing(logo);
                            this.add.existing(text);

                            [text, logo].forEach((e) => {
                                e.setInteractive()
                                e.on(POINTER_DOWN, () => {
                                    logo.destroy();
                                    text.destroy();
                                    bgRect.destroy();
                                    this.resetScene();
                                });
                            });
                        }, 2000);
                    }
                } else {
                    // B. Clicked on another musician
                    this.resetAllAnimation(event);
                }
            }
        });
    }

    private resetAllAnimation(event: MusicianClickedEvent) {
        event.who.sayNo();
        this.resetScene();
    }

    private resetScene() {
        this.players.forEach(m => m.spotlightOff());
        this.music.mute = true;
        this.percussions.mute = true;
        this.voice.mute = true;
        this.playing = [];
        this.instr.forEach(i => {
            i.body['reset'](this.hiddenPoint.x, this.hiddenPoint.y);
            i.isOnHiddenSpot = true;
        });
        clearInterval(this.confettiInterval);
        this.gameState = GameState.PLAYING;
    }

    private explodeConfetti(confettiTop: Phaser.GameObjects.Particles.ParticleEmitter, width: number, confettiPlayer: Phaser.GameObjects.Particles.ParticleEmitter) {
        confettiTop.explode(30, width / 3, -20);
        confettiTop.explode(30, width / 3 * 2, -20);
        this.players.forEach(m => confettiPlayer.explode(20, m.x, m.y));
    }

    pulsing = false;

    update() {

        if (this.instr.every((instrument: Instrument) => instrument.isOnHiddenSpot)) {
            this.gameState = GameState.PLAYING;
        }

        const tolerance = 200 * 1.5 / this.game.loop.targetFps;
        switch (this.gameState) {
            case GameState.BEGINNING:
                //  4 is our distance tolerance, i.e. how close the source can get to the target
                //  before it is considered as being there. The faster it moves, the more tolerance is required.
                // const tolerance = 4;
                this.instr.forEach((instrument: Instrument) => {
                    const distance = Phaser.Math.Distance.BetweenPoints(instrument, this.hiddenPoint);
                    if (instrument.body['speed'] > 0) {
                        if (distance < tolerance) {
                            instrument.body['reset'](this.hiddenPoint.x, this.hiddenPoint.y);
                            instrument.isOnHiddenSpot = true;
                        }
                    }
                });
                break;
            case GameState.PLAYING:
                if (this.currentInstrument < 5) {
                    this.physics.moveToObject(this.instr[this.currentInstrument], this.goodSpot, GAME_SPEED);
                    this.instr[this.currentInstrument].isOnHiddenSpot = false;
                    const distance = Phaser.Math.Distance.BetweenPoints(this.instr[this.currentInstrument], this.goodSpot);
                    if (this.instr[this.currentInstrument].body['speed'] > 0) {
                        if (distance < tolerance+10) {
                            this.instr[this.currentInstrument].body['reset'](this.goodSpot.x, this.goodSpot.y);
                        }
                    }
                }
                this.players.forEach((musician) => {
                    const distance = Phaser.Math.Distance.BetweenPoints(musician.showInstrument, musician);
                    if (musician.showInstrument.body['speed'] > 0) {
                        if (distance < tolerance+10) {
                            musician.showInstrument.body['reset'](musician.x, musician.y);
                        }
                    }
                });
                break;
            case GameState.ENDING:
                break;
        }

        if (this.playing.length < 5) {

            this.instr.forEach((instrument) => {

                if (!this.pulsing && Math.abs(instrument.x - (this.goodSpot.x)) < 50 && Math.abs(instrument.y - (this.goodSpot.y)) < 50) {
                    this.pulsing = true;
                    this.goodSpot.pulse(() => this.pulsing = false);
                }
            });
        }
    }

}

function loopIncrement(input: number): number {
    return input < 4 ? input+1 : 0;
}
