import * as Phaser from 'phaser';
import PreloadJahiner from "./PreloadJahiner";
import Jahiner from './jahiner';

const config: Phaser.Types.Core.GameConfig = {
    type: Phaser.AUTO,
    scene: [PreloadJahiner, Jahiner],
    backgroundColor: '#204545',
    scale: {
        parent: 'game',
        mode: Phaser.Scale.RESIZE,
    },
    physics: {
        default: 'arcade',
        // arcade: { debug: true }
    }
}

new Phaser.Game(config)
