import * as Phaser from 'phaser';

type PartialTweenBuilderConfig = Omit<Phaser.Types.Tweens.TweenBuilderConfig, 'target'>;

export function shake(scene: Phaser.Scene, target: any, config?: PartialTweenBuilderConfig): void {

    const defaultConfig: PartialTweenBuilderConfig = {
        x: 5,
        y: 0,
        duration: 50,
        yoyo: true,
        repeat: 8,
        ease: 'Bounce.easeInOut',
        delay: 0,
        paused: false,
    }

    const options: PartialTweenBuilderConfig = Object.assign(defaultConfig, config ?? {});

    scene.tweens.add({
        ...options,
        targets: target,
        x: target.x + options.x,
        y: target.y - options.y,
    });
}

export function flash(scene: Phaser.Scene, target: any, duration = 150, color = '0xffffff') {

    target.setTintFill(color);

    scene.time.addEvent({
        delay: duration,
        callback: () => {
            target.setTint('0xffffff');
        },
    });
}

export function idle(scene: Phaser.Scene, target: any, config?: PartialTweenBuilderConfig): Phaser.Tweens.Tween {

    const defaultConfig: PartialTweenBuilderConfig = {
        y: target.y + (target.height * 0.025),
        scaleY: target.scaleY - 0.05,
        duration: 150,
        delay: 0,
        paused: false,
        yoyo: true,
        ease: 'Sine.easeInOut',
        loop: -1,
    }

    const options: PartialTweenBuilderConfig = Object.assign(defaultConfig, config ?? {});

    return scene.tweens.add({
        ...options,
        targets: target,
    });
}

export function pulse(scene: Phaser.Scene, target: any, config?: PartialTweenBuilderConfig): Phaser.Tweens.Tween {
    const defaultConfig: PartialTweenBuilderConfig = {
        scaleX: target.scaleX * 1.5,
        scaleY: target.scaleY * 1.5,
        duration: 550,
        yoyo: true,
        ease: 'Quad.easeInOut',
        delay: 0,
        paused: false,
    }

    const options: PartialTweenBuilderConfig = Object.assign(defaultConfig, config ?? {});

    return scene.tweens.add({
        ...options,
        targets: target,
    });
}
