import * as Phaser from 'phaser';

const BASS_DRUM = 'bass_drum';
const SNARE_DRUM = 'snare_drum';
const BOMBARDE = 'bombarde';
const BAGPIPES = 'bagpipes';
const BRETON_BAGPIPES = 'breton_bagpipes';

type Name = typeof BASS_DRUM | typeof SNARE_DRUM | typeof BOMBARDE | typeof BAGPIPES | typeof BRETON_BAGPIPES;
const Instruments: Name[] = [BASS_DRUM, SNARE_DRUM, BOMBARDE, BAGPIPES, BRETON_BAGPIPES];

class Instrument extends Phaser.GameObjects.Image {

    instrument: typeof Instruments[number];
    isOnHiddenSpot: boolean = false;
    constructor(
        scene: Phaser.Scene, name: Name, x: number, y: number) {
        super(scene, x, y, name);
        this.scale = 0.25;
        scene.add.existing(this);
        scene.physics.add.existing(this)
        this.instrument = name;
        this.setDepth(1);
    }
}

export {
    Instrument,
    Instruments,
    BASS_DRUM,
    SNARE_DRUM,
    BOMBARDE,
    BAGPIPES,
    BRETON_BAGPIPES,
};
