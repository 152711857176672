import * as Phaser from 'phaser';
import Circle = Phaser.Geom.Circle;
import { Musicians } from './musician';
import { pulse } from './juice/functions';

class GoodSpot extends Phaser.GameObjects.Image {

    arrows: Phaser.GameObjects.Image[] = [];

    constructor(scene: Phaser.Scene, x: number, y: number, directions: [number, number][]) {
        super(scene, x, y, 'dot');
        const circle = new Circle(x, y, 120);
        directions.forEach((direction, index) => {
            this.arrows.push(drawArrow(scene, circle, (180/(Musicians.length + 1) * (index+1)) / 360, direction));
        });
        scene.add.existing(this);
    }

    pulse(callback: () => void) {
        this.arrows.forEach((arrow) => {
            pulse(this.scene, arrow, {
                scaleX: 1.3,
                scaleY: 1.3,
                duration: 200,
            }).setCallback('onComplete', () => {
                arrow.scaleX = 1;
                arrow.scaleY = 1;
            });
        });
        pulse(this.scene, this, {
            scaleX: 1.05,
            scaleY: 1.05,
            duration: 200,
        }).setCallback('onComplete', () => { // tween: Phaser.Tweens.Tween
            this.scaleX = 1;
            this.scaleY = 1;
            callback();
        });
    }
}

function drawArrow(scene: Phaser.Scene, circle: Circle, position: number, rotation: [number, number]): Phaser.GameObjects.Image {
    const { x, y } = Circle.GetPoint(circle, position);
    const image = scene.add.image(x, y, 'arrow');
    image.rotation = Phaser.Math.Angle.Between(image.x, image.y, rotation[0], rotation[1]);
    return image;
}

export { GoodSpot };
