import * as Phaser from 'phaser';
import {Musicians} from "./musician";
import {Instruments} from "./instrument";
import POINTER_DOWN = Phaser.Input.Events.POINTER_DOWN;

export default class PreloadJahiner extends Phaser.Scene {

    constructor() {
        super('preloading');
    }

    preload() {
        this.load.image('logo', 'assets/logo.png');
        this.load.image('background', 'assets/background.jpg');
    }

    create() {
        const width = this.game.scale.width;
        const height = this.game.scale.height;
        const x = Number(width) / 2;
        const y = Number(height) / 2;
        const background = this.add.image(x,y, 'background');
        background.setDisplaySize(width, height);
        const logo = this.add.image(x, y, 'logo');

        Musicians.forEach(name => {
            // this.load.image(`${name}-image`, `assets/${name}/image.webp`);
            this.load.image(`${name}-image`, `assets/${name}/foto.png`);
            this.load.audio(`${name}-voice`, `assets/${name}/voice.mp3`);
            this.load.audio(`${name}-instrument`, `assets/${name}/instrument.mp3`);
        });
        Instruments.forEach(name => this.load.image(name, `assets/${name}.png`));

        this.load.atlas('flares', 'assets/particles/flares.png', 'assets/particles/flares.json');

        this.load.image('arrow', 'assets/arrow.png');
        this.load.image('dot', 'assets/dot.png');

        this.load.audio(`music`, `assets/song/music.mp3`);
        this.load.audio(`percussions`, `assets/song/percussions.mp3`);
        this.load.audio(`voice`, `assets/song/voice.mp3`);

        this.load.audio(`no1`, `assets/words/no/je_refuse.mp3`);
        this.load.audio(`no2`, `assets/words/no/impossible.mp3`);
        this.load.audio(`no3`, `assets/words/no/non_merci.mp3`);
        this.load.audio(`no4`, `assets/words/no/pas_ca.mp3`);
        this.load.audio(`no5`, `assets/words/no/non_non_non.mp3`);
        this.load.audio(`yes1`, `assets/words/yes/ale_jouons.mp3`);
        this.load.audio(`yes2`, `assets/words/yes/enfin.mp3`);
        this.load.audio(`yes3`, `assets/words/yes/tres_content.mp3`);
        this.load.audio(`yes4`, `assets/words/yes/yeah_babe.mp3`);
        this.load.audio(`yes5`, `assets/words/yes/zorbov.mp3`);

        this.load.once(Phaser.Loader.Events.COMPLETE, async () => {
            const text = this.add.text(x - logo.width/2, y + logo.height / 2, 'Jouez avec nous', {
                font: '3em'
            });
            [text, logo].forEach((e) => {
                e.setInteractive()
                e.on(POINTER_DOWN, () => this.scene.start('jahiner'));
            });
        });

        this.load.start();
    }
}
