import * as Phaser from 'phaser';
import { EventDispatcher } from './eventDispatcher';
import { BAGPIPES, BASS_DRUM, BOMBARDE, BRETON_BAGPIPES, Instrument, Instruments, SNARE_DRUM } from './instrument';
import { flash, idle, shake } from './juice/functions';
import POINTER_DOWN = Phaser.Input.Events.POINTER_DOWN;
import Ellipse = Phaser.GameObjects.Ellipse;
import Triangle = Phaser.GameObjects.Triangle;

const ANGUS = 'angus';
const BUSTER = 'buster';
const VALLERY = 'vallery';
const VIKAASH = 'vikaash';
const ZARYAD = 'zaryad';

type Name = typeof ANGUS | typeof BUSTER | typeof VALLERY | typeof VIKAASH | typeof ZARYAD
const Musicians: Name[] = [ANGUS, BUSTER, VALLERY, VIKAASH, ZARYAD]

type MusicianClickedEvent = {
    name: string,
    who: Musician
}

const shadowOffColor = 0x222222;
const spotlightColor = 0xDDDD00;
const shadowOnColor = 0x777700;

class Musician extends Phaser.GameObjects.Image {

    instrument: typeof Instruments[number];
    ellipse: Ellipse;
    spotlight: Triangle;
    private say_no: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private say_yes: Phaser.Sound.NoAudioSound | Phaser.Sound.HTML5AudioSound | Phaser.Sound.WebAudioSound;
    private idleAnimation?: Phaser.Tweens.Tween;
    showInstrument?: Instrument;

    constructor(scene: Phaser.Scene, name: Name, x: number, y: number) {
        super(scene, x, y, `${name}-image`);
        this.scale = 0.5;

        const footY = 130;
        const ellipseHeight = 70;
        const stretch = 300;
        const randomX = Math.random() * (this.width / 3.5);

        this.spotlight = new Triangle(scene,
            x, y,
            randomX, -stretch, /*First point*/
            0, footY + ellipseHeight * 2 + stretch, /*Second point*/
            (this.width / 3.5), footY + ellipseHeight * 2 + stretch, /*Third point*/
            spotlightColor, 0);
        scene.add.existing(this.spotlight);

        this.ellipse = new Ellipse(scene, x, y + footY, this.width / 3.5, ellipseHeight, shadowOffColor);
        scene.add.existing(this.ellipse);

        scene.add.existing(this);
        this.setInteractive();

        switch (name) {
            case "angus":
                this.instrument = BAGPIPES;
                this.say_no = scene.sound.add(`no1`);
                this.say_yes = scene.sound.add(`yes1`);
                break
            case "buster":
                this.instrument = BASS_DRUM;
                this.say_no = scene.sound.add(`no2`);
                this.say_yes = scene.sound.add(`yes2`);
                break
            case "vallery":
                this.instrument = BOMBARDE;
                this.say_no = scene.sound.add(`no3`);
                this.say_yes = scene.sound.add(`yes3`);
                break
            case "vikaash":
                this.instrument = SNARE_DRUM;
                this.say_no = scene.sound.add(`no4`);
                this.say_yes = scene.sound.add(`yes4`);
                break
            case "zaryad":
                this.instrument = BRETON_BAGPIPES;
                this.say_no = scene.sound.add(`no5`);
                this.say_yes = scene.sound.add(`yes5`);
                break
        }

        this.showInstrument = new Instrument(scene, this.instrument, x, y);
        // this.showInstrument = new Image(scene, x, y, this.instrument);
        this.showInstrument.setScale(0.3);
        // idle(scene, this.showInstrument);
        scene.add.existing(this.showInstrument);

        setTimeout(() => {
                this.idleAnimation = idle(scene, this, {
                    y: this.y + (this.height * 0.005),
                    scaleY: this.scaleY - 0.01,
                    duration: 300,
                    delay: 0,
                    yoyo: true,
                    ease: 'Sine.easeInOut',
                    loop: -1,
                });
            },
            Math.random() * 100
        );

        this.on(POINTER_DOWN, () => {
            const customEvent: MusicianClickedEvent = {
                name: name,
                who: this
            };
            EventDispatcher.getInstance().emit('MUSICIAN_CLICKED', customEvent);
        })
    }

    public sayNo(): void {
        if (!this.say_no.isPlaying && !this.say_yes.isPlaying) {
            this.say_no.play({
                volume: 1.2,
                delay: 0,
            });
        }
        flash(this.scene, this);
        this.idleAnimation?.setCallback('onComplete', () => {
            shake(this.scene, this, {
                onComplete: () => {
                    this.idleAnimation = idle(this.scene, this, {
                        y: this.y + (this.height * 0.005),
                        scaleY: this.scaleY - 0.01,
                        duration: 300,
                        delay: 0,
                        yoyo: true,
                        ease: 'Sine.easeInOut',
                        loop: -1,
                    })
                }
            });
        });
    }

    public sayYes(): void {
        if (!this.say_no.isPlaying && !this.say_yes.isPlaying) {
            this.say_yes.play({
                volume: 3
            });
        }
    }

    public spotlightOn(): void {
        this.spotlight.setFillStyle(spotlightColor, 0.7);
        this.ellipse.setFillStyle(shadowOnColor);
        if (this.idleAnimation) {
            this.idleAnimation.setCallback('onComplete', () => {
                this.idleAnimation = idle(this.scene, this);
            });
            this.idleAnimation.completeAfterLoop();
        } else {
            this.idleAnimation = idle(this.scene, this);
        }
    }

    public spotlightOff(): void {
        this.spotlight.setFillStyle(spotlightColor, 0);
        this.ellipse.setFillStyle(shadowOffColor);
        if (this.idleAnimation) {
            this.idleAnimation.setCallback('onComplete', () => {
                this.idleAnimation = idle(this.scene, this, {
                    y: this.y + (this.height * 0.005),
                    scaleY: this.scaleY - 0.01,
                    duration: 400,
                    delay: 0,
                    yoyo: true,
                    ease: 'Sine.easeInOut',
                    loop: -1,
                });
            });
            this.idleAnimation.completeAfterLoop()
        } else {
            this.idleAnimation = idle(this.scene, this, {
                y: this.y + (this.height * 0.005),
                scaleY: this.scaleY - 0.01,
                duration: 400,
                delay: 0,
                yoyo: true,
                ease: 'Sine.easeInOut',
                loop: -1,
            });
        }
    }
}

export {
    Musician,
    Musicians,
    MusicianClickedEvent
};
